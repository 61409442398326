import React from 'react';
/* eslint-disable-next-line no-restricted-imports */
import styled from 'styled-components';

import getFontDetailsByLabel from '@headout/aer/src/tokens/typography';

import { StarIcon } from 'Constants/descriptorSvg';

import TYPE_LABELS from 'Static/typography/labels';

import Star from './star';

export const RatingContainer = styled.div`
	display: flex;
	align-items: center;
	.star-count {
		margin-left: 0.25rem;
		${getFontDetailsByLabel(TYPE_LABELS.UI_LABEL_MEDIUM_HEAVY)};
	}
`;

type Props = {
	value?: number;
	isMobile?: boolean;
	fillColor?: string;
	emptyColor?: string;
};

const Rating = ({ value, fillColor, isMobile }: Props) => {
	if (isMobile)
		return (
			// @ts-expect-error TS(2769): No overload matches this call.
			<RatingContainer isMobile={isMobile}>
				<StarIcon />
			</RatingContainer>
		);

	const stars = [];
	for (let i = 1; i <= 5; i++) {
		let star = 0;
		// @ts-expect-error TS(2532): Object is possibly 'undefined'.
		if (value >= i) {
			star = 1;
			// @ts-expect-error TS(2532): Object is possibly 'undefined'.
		} else if (value < i - 1) {
			star = 0;
		} else {
			// @ts-expect-error TS(2532): Object is possibly 'undefined'.
			star = value - i + 1;
		}
		stars.push(star);
	}

	return (
		<RatingContainer>
			{stars.map((val, index) => (
				<Star fillValue={val} key={index} fillColor={fillColor} />
			))}
			<span className='star-count'>{value}/5</span>
		</RatingContainer>
	);
};

export default React.memo(Rating);
