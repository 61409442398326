import React from 'react';
/* eslint-disable-next-line no-restricted-imports */
import styled from 'styled-components';

import { StarEmptySvg, StarFullSvg, StarHalfSvg } from 'Assets/svg/star';

const StarContainer = styled.div`
	display: inline-block;
	margin-right: 0.125rem;

	:last-child {
		margin-right: 0;
	}
`;

const StarSvg = ({ fillValue, fillColor }: any) => {
	if (fillValue < 0.25) {
		return <StarEmptySvg />;
	} else if (fillValue < 0.75) {
		return <StarHalfSvg fill={fillColor} />;
	}
	return <StarFullSvg color={fillColor} />;
};

const Star = ({ fillValue, fillColor }: any) => (
	<StarContainer>
		<StarSvg fillValue={fillValue} fillColor={fillColor} />
	</StarContainer>
);

export default Star;
